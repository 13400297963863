import React from 'react'
import Helmet from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  BannerFull,
  AppointmentIndividual,
  BlogSlider,
  TextBox,
  CallUs,
  Brands,
  Layout,
  ProjectSlider,
  BannerLinks,
  SoftwareStack,
  QuickFacts,
  ErpModule
} from '../components/'
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'

import { createProjectSlides } from '../utils'

import contacts from '../components/CallUs/DefaultContacts'
import boxes from '../components/BannerLinks/DefaultBox'
import HolzwegLinks from '../components/HolzwegLinks'
import TextIconContainer from '../components/TextIconContainer'
import BannerImage from '../assets/projektuebersicht.jpg'

import Berufsreise from '../assets/brands/berufsreise.png'
import Colompac from '../assets/brands/colompac.png'
import Dikb from '../assets/brands/dibk.png'
import Tiwag from '../assets/brands/tiwag.png'
import WerHilftWie from '../assets/brands/werhilftwie.png'
import Eglo from '../assets/brands/eglo.png'
import EnergieTirol from '../assets/brands/energie.tirol.png'
import HallAg from '../assets/brands/hall.ag.png'
import Hall from '../assets/brands/hall.png'
import Ikb from '../assets/brands/ikb.png'
import RauchMehl from '../assets/brands/rauchmehl.png'
import Intercable from '../assets/brands/intercable.png'
import Pan from '../assets/brands/pan.png'
import Prinoth from '../assets/brands/prinoth.png'
import Wedl from '../assets/brands/wedl.png'
import Haleon from '../assets/brands/haleon_bw.png'
import Senoplast from '../assets/brands/senoplast.png'
import Senova from '../assets/brands/senova.png'
import Alpinamed from '../assets/brands/alpinamed.png'
import GebroPharma from '../assets/brands/gebropharma.png'
import Stubai from '../assets/brands/stubai.png'
import Zellparts from '../assets/brands/zellparts.png'
import ArbeiterKammer from '../assets/brands/ak.png'
import OmegaPharma from '../assets/brands/omegapharma.png'
import BioHeizwerk from '../assets/brands/bioheizwerk.png'
import Morf from '../assets/brands/morf.png'
import NHT from '../assets/brands/nht.png'
import Alupress from '../assets/brands/alupress.png'
import WKO from '../assets/brands/wkohs.png'
import anja_thumbnail from '../assets/anja-friedensdorf-interview.jpg'
import hwLogo3D from '../assets/hwlogo/Hw3DLogoScreenshot.png'
import exitSign from '../assets/exit_sign.jpg'
import error from '../assets/error.jpg'

import Farbverlauf from '../assets/verlauf.jpg'
import SandsofTime from '../assets/sandsoftime.jpg'
import Mouse from '../assets/mouse.opt.gif'
import HandyNav from '../assets/handynavigation.jpg'
import HowIsYourDay from '../assets/splitscreens.jpg'
import UserFirst from '../assets/blog.userfirst.jpg'
import matomo_m from '../assets/matomo_m.png'
import ie_grave from '../assets/internet-explorer-gravestone-overlay.png'

// import berufsreise from '../../assets/projectslider/slider.berufsreise.groß.jpg'
import morf from '../assets/projectslider/slider.morf.gross.jpg'
import ikb from '../assets/projectslider/slider.ikb.groß.jpg'
import dibk from '../assets/projectslider/slider.dibk.klein.jpg'
// import edelwatt from '../assets/projectslider/slider.edelwatt.klein.jpg'
import werhilftwie from '../assets/projectslider/slider.werhilftwie.klein.png'
import eglo from '../assets/projectslider/slider.eglo.klein.jpg'
// import giesswein from '../assets/projectslider/slider.giesswein.klein.jpg'
// import intercable from '../assets/projectslider/slider.intercable.klein.jpg'
import ak from '../assets/projectslider/slider.aktirol.klein.png'
import stadtamthall from '../assets/projectslider/slider.stadtamthall.klein.png'
// import tiwag from '../assets/projectslider/slider.tiwag.klein.jpg'
// import tyczka from '../assets/projectslider/slider.tyczka.klein.jpg'
import voltadol from '../assets/projectslider/slider.voltadol.klein.jpg'
import wedl from '../assets/projectslider/slider.wedl.klein.jpg'
// import tq from '../assets/projectslider/slider.tq.png'
import wko from '../assets/projectslider/slider.wko.klein.jpeg'
import klepschgroup from '../assets/projectslider/slider.klepschgroup.jpg'
import bookingimage from '../assets/Buchungsjournal.png'
import alupress from '../assets/projekte/alupress/home.png'

const brands = [
  {
    image: Berufsreise,
    alt: 'Berufsreise',
    link: '/projekte/berufsreise'
  },
  {
    image: WKO,
    alt: 'WKO',
    link: '/projekte/wirtschaftskammer'
  },

  {
    image: Dikb,
    alt: 'Diözese Innsbruck',
    link: '/projekte/dioezese'
  },
  {
    image: Colompac,
    alt: 'Colompac',
    link: '/projekte'
  },
  {
    image: Tiwag,
    alt: 'Tiwag',
    link: '/projekte'
  },
  {
    image: WerHilftWie,
    alt: 'WerHilftWie',
    link: '/projekte/werhilftwie'
  },
  {
    image: Eglo,
    alt: 'EGLO Leuchten',
    link: '/projekte/eglo-pim'
  },
  {
    image: EnergieTirol,
    alt: 'Energie Tirol',
    link: '/projekte/energietirol'
  },
  {
    image: HallAg,
    alt: 'Hall AG',
    link: '/projekte'
  },
  {
    image: Hall,
    alt: 'Hall in Tirol',
    link: '/projekte/stadtamthall'
  },
  {
    image: Ikb,
    alt: 'Innsbrucker Kommunalbetriebe',
    link: '/projekte/ikb'
  },
  {
    image: Intercable,
    alt: 'Intercable',
    link: '/projekte/intercable'
  },
  {
    image: RauchMehl,
    alt: 'RauchMehl',
    link: '/projekte/rauchmehl'
  },
  {
    image: Pan,
    alt: 'Pan',
    link: '/projekte'
  },
  {
    image: Prinoth,
    alt: 'Prinoth',
    link: '/projekte'
  },
  {
    image: Wedl,
    alt: 'Wedl',
    link: '/projekte/wedl'
  },
  {
    image: Haleon,
    alt: 'Haleon',
    link: '/projekte/haleon-gebro'
  },
  {
    image: Senoplast,
    alt: 'Senoplast',
    link: '/projekte/klepsch-group'
  },
  {
    image: Senova,
    alt: 'Senova',
    link: '/projekte/klepsch-group'
  },
  {
    image: Alpinamed,
    alt: 'Alpinamed',
    link: '/projekte'
  },
  {
    image: GebroPharma,
    alt: 'GebroPharma',
    link: '/projekte'
  },
  {
    image: Stubai,
    alt: 'Stubai',
    link: '/projekte'
  },
  {
    image: Zellparts,
    alt: 'Zellparts',
    link: '/projekte/klepsch-group'
  },
  {
    image: ArbeiterKammer,
    alt: 'AK',
    link: '/projekte/arbeiterkammer'
  },
  {
    image: OmegaPharma,
    alt: 'GebroPharma',
    link: '/projekte'
  },
  {
    image: BioHeizwerk,
    alt: 'BioHeizwerk',
    link: '/projekte'
  },
  {
    image: Morf,
    alt: 'Morf',
    link: '/projekte/morf'
  },
  {
    image: NHT,
    alt: 'Neue Heimat Tirol',
    link: '/projekte/nht'
  },
  {
    image: Alupress,
    alt: 'Alupress',
    link: '/projekte/'
  }
]

const facts = [
  {
    count: 250,
    suffix: '',
    text: 'Zufriedene Kund:innen'
  },
  {
    count: 100,
    suffix: '%',
    text: 'lokaler Ansprechpartner'
  },
  {
    count: 42798,
    suffix: '',
    text: 'GIT commits'
  }
]

const projects = createProjectSlides({
  bigImages: [
    {
      image: morf,
      text: (
        <span>
          <h2>MOMO - Ein Tool für alles</h2>
          <p>
            Unser individuell programmiertes ERP-System "Momo" bietet im
            Straßenmarkierungsbereich optimale Lösungen. Mit digitaler
            Baustellenassistenz, Unternehmensverwaltung, Projektmanagement und
            agiler Webentwicklung steigert Momo Effizienz, ermöglicht
            Offline-Nutzung und sichert Wettbewerbsvorteile.{' '}
          </p>
        </span>
      ),
      link: '/hw-erp'
    },
    {
      image: wko,
      text: (
        <span>
          <h2>Wirtschaftskammer Tirol</h2>
          <p>
            holzweg hat eine innovative Lern-App entwickelt, die der
            betriebsinternen Fort- und Weiterbildung dient und besonders auf die
            Lehrlingsausbildung spezialisiert ist. In Zusammenarbeit mit dem
            Fachexperten Roland Teißl wurde die Lern-App konzipiert, um die
            Lehrlingsausbildung für verschiedene Branchen zu optimieren.
          </p>
        </span>
      ),
      link: '/projekte/wirtschaftskammer'
    },
    {
      image: ikb,
      text: (
        <span>
          <h2>Innsbrucker Kommunalbetriebe</h2>
          <p>
            Als kommunales Infrastruktur-Dienstleistungsunternehmen dient die
            Webseite der IKB vor allem als Informationsportal und somit als
            weiterer Service für ihre Kunden. Bei einer Webseite mit so vielen
            Inhalten wie auf ikb.at steht die Skalierbarkeit aus technischer
            Sicht an erster Stelle.
          </p>
        </span>
      ),
      link: '/projekte/ikb'
    }
  ],
  smallImages: [
    {
      image: dibk,
      text: (
        <span>
          <h2>Diözese Innsbruck Relaunch</h2>
          <p>
            Beim letzten kompletten Redesign 2016 realisierten wir ein
            hochkomplexes Mutliportalsystem, welches aus hunderten unabhängigen
            Internetauftritten besteht.
          </p>
        </span>
      ),
      link: '/projekte/dioezese'
    },
    {
      image: ak,
      text: (
        <span>
          <h2>Arbeiterkammer Tirol</h2>
          <p>
            Online mit Köpfchen - dieses Quiztool ermöglicht das spielerische
            Lernen von Themen wie Datenschutz und Medienkompetenz.
          </p>
        </span>
      ),
      link: '/projekte/arbeiterkammer'
    },
    {
      image: stadtamthall,
      text: (
        <span>
          <h2>Stadtamthall</h2>
          <p>
            Die Bürgerthemen werden durch die neu gestaltete Website ordentlich
            aber lebendig dargestellt.
          </p>
        </span>
      ),
      link: '/projekte/stadtamthall'
    },
    {
      image: werhilftwie,
      text: (
        <span>
          <h2>Wer Hilft Wie Suchmaschine</h2>
          <p>
            DIE Suchmaschine für Soziale Einrichtungen in Tirol. Die Adressen
            und Beschreibungen der Einrichtungen sind Kategorien und
            Suchbegriffen zugeordnet.
          </p>
        </span>
      ),
      link: '/projekte/werhilftwie'
    },
    {
      image: voltadol,
      text: (
        <span>
          <h2>Haleon Gebro</h2>
          <p>
            all in one: Multidomain-Website, Echtzeit-Apothekenfinder,
            personalisierter Camping-Checkliste, individuellen Bestellungen im
            geschützten Apothekenbereich.
          </p>
        </span>
      ),
      link: '/projekte/haleon-gebro'
    },
    {
      image: wedl,
      text: (
        <span>
          <h2>Wedl Handelshaus Relaunch</h2>
          <p>
            holzweg und Digithaler Relaunch: kundenorientierte Website mit
            ansprechendem Design und übersichtlichem Jobportal
          </p>
        </span>
      ),
      link: '/projekte/wedl'
    },
    {
      image: eglo,
      text: (
        <span>
          <h2>EGLO PIM</h2>
          <p>
            Gemeinsam mit unserem langjährigen Kunden EGLO wurde vor drei Jahren
            ein internes Produktinformationsmanagement (PIM) System für über
            240.000 Produkte ins Leben gerufen.{' '}
          </p>
        </span>
      ),
      link: '/projekte/eglo-pim'
    },
    {
      image: alupress,
      text: (
        <span>
          <h2>Alupress</h2>
          <p>
            Webprojekt für Alupress AG erfolgreich abgeschlossen! Typo3
            ermöglicht anpassbare, benutzerfreundliche Website. Modernes Design,
            innovative UI-Elemente bieten fesselnde Nutzererfahrung.
          </p>
        </span>
      ),
      link: '/projekte/alupress'
    }
  ]
})

const cards = [
  {
    tag: 'Technologie',
    headline: 'KI im Backend',
    backgroundImage: BannerImage,
    readingTime: null,
    whiteColor: true,
    link: '/blog/ki-im-backend'
  },
  {
    tag: 'Technologie',
    headline: 'Interaktive 3D-Modelle',
    backgroundImage: hwLogo3D,
    readingTime: null,
    blackColor: true,
    link: '/blog/interaktive-3d-modelle'
  },
  {
    tag: 'UI - User Interface',
    headline: 'Exit Intent Layer',
    backgroundImage: exitSign,
    readingTime: null,
    whiteColor: true,
    link: '/blog/exit-intent-layer'
  },
  {
    tag: 'UI - User Interface',
    headline: 'Custom Error Pages',
    backgroundImage: error,
    readingTime: null,
    whiteColor: true,
    link: '/blog/custom-error-pages'
  },
  {
    tag: 'Karriere',
    headline: 'Interview zum Girls Day 2022',
    backgroundImage: anja_thumbnail,
    readingTime: null,
    whiteColor: true,
    link: '/blog/girlsday-2022'
  },
  {
    tag: 'Technologie',
    headline: 'Der Internet Explorer wird eingestellt',
    backgroundImage: ie_grave,
    readingTime: null,
    whiteColor: true,
    link: '/blog/internet-explorer-death'
  },
  {
    tag: 'DSGVO',
    headline: 'matomo statt Google Analytics!',
    subheadline: 'Meiden auch Sie hohe Bußgeldstrafen',
    backgroundImage: matomo_m,
    readingTime: null,
    whiteColor: true,
    link: '/blog/matomo_statt_google/'
  },
  {
    tag: 'UI - User Interface',
    headline: 'User first!',
    subheadline: 'weil mobile-first ist desktop-worst',
    backgroundImage: UserFirst,
    readingTime: 2,
    whiteColor: true,
    link: '/blog/user-first/'
  },
  // {
  //   tag: 'Technologie',
  //   headline: 'DSGVO - einfach!',
  //   subheadline: 'DSGVO Checkliste zum Abhaken',
  //   backgroundImage: null,
  //   readingTime: 2,
  //   link: '/blog/dsgvo-einfach/'
  // },

  {
    tag: 'Projektmanagement',
    headline: 'Ticketsystem',
    subheadline: 'Strukturierte Kommunikation während des Projektes',
    backgroundImage: null,
    readingTime: null,
    blackColor: true,
    link: '/blog/customer-portal/'
  },
  {
    tag: 'UI - User Interface',
    headline: 'Gradient your life',
    subheadline: 'Farbverläufe und Transitions',
    backgroundImage: Farbverlauf,
    readingTime: null,
    whiteColor: true,
    link: '/blog/gradient-your-life/'
  },
  {
    tag: 'UI - User Interface',
    headline: 'Bild-befüllter Text',
    subheadline: 'Stylish.Punkt.',
    backgroundImage: SandsofTime,
    readingTime: null,
    link: '/blog/bild-befuellter-text/'
  },
  {
    tag: 'UI - User Interface',
    headline: 'Mobiles Menü ganz unten',
    subheadline: 'Daumen brauchen neue Menüführung',
    backgroundImage: HandyNav,
    readingTime: 5,
    whiteColor: true,
    link: '/blog/mobilenavigationganzunten/'
  },
  {
    tag: 'Technologie',
    headline: 'Cross-Testing',
    subheadline: 'Wie funktioniert das?',
    backgroundImage: null,
    readingTime: 1,
    link: '/blog/cross-testing/'
  },
  {
    tag: 'UX - User Experience',
    headline: 'Micro Interaction',
    subheadline: 'Verbesserung der User Experience',
    backgroundImage: Mouse,
    readingTime: null,
    link: '/blog/micro-interaction/'
  },
  {
    tag: 'UI - User Interface',
    headline: 'Split Screens',
    subheadline: 'Dualität mit Style Faktor',
    backgroundImage: HowIsYourDay,
    readingTime: null,
    whiteColor: true,
    link: '/blog/split-screens/'
  },
  {
    tag: 'SEO',
    headline: '95% Uplift mit SEO',
    subheadline: 'Reichweite der Webseite maximieren',
    backgroundImage: null,
    readingTime: 5,
    link: '/blog/mittels-seo-zur-besten-platzierung'
  }
]

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Helmet
        title="Digitale Full-Service-Agentur holzweg GmbH® aus Innsbruck"
        meta={[
          {
            name: 'description',
            content:
              'Die holzweg GmbH® liefert als Full-Service-Agentur Strategie ✓ Web-Design ✓ und  Technologie ✓ aus einer Hand und erarbeitet für Sie die besten digitalen Lösungen. '
          },
          {
            name: 'keywords',
            content:
              'webagentur innsbruck, digitale agentur tirol, online agentur'
          },
          {
            property: 'og:description',
            content:
              'Die holzweg GmbH® liefert als Full-Service-Agentur Strategie ✓ Web-Design ✓ und  Technologie ✓ aus einer Hand und erarbeitet für Sie die besten digitalen Lösungen. '
          },
          {
            property: 'og:image',
            content:
              'https://kooperation.holzweg.com/wp-content/uploads/2019/11/holzweglogo.png'
          }
        ]}
      />
      {/* <PopUp 
      title="Entdecken Sie neue Trends"
      description={
        <p>
          Entdecken Sie die neuesten Trends und Entwicklungen im Bereich Webdesign und Technologie. Unsere Expert:innen teilen ihr Wissen und ihre Erfahrungen in unserem Blog. Erfahren Sie mehr über die neuesten Entwicklungen im Bereich Webdesign, Technologie und digitale Strategien. Wir bieten Ihnen Einblicke in die Welt der Webentwicklung und zeigen Ihnen, wie Sie Ihr Unternehmen mit den neuesten Technologien und Trends erfolgreich machen können.
        </p>
      }
      linkText="Zum Blog"
      link="/blog"
      timer={true}
      /> */}
      <BannerFull />
      <TextBox
        title={
          <span>
            Alles hat 3 Seiten:
            <br />
            strategie.
            <wbr />
            <nobr>design.</nobr>
            <wbr />
            technologie
          </span>
        }
        text="holzweg ist eine Internetagentur in Innsbruck, die sich auf die Entwicklung maßgeschneiderter Individualsoftware und Websites spezialisiert hat. Wir bieten ein breites Spektrum an Lösungen, darunter auch benutzerdefinierte ERP-Systeme. Unser Ziel ist es, kundenzentrierte Lösungen zu schaffen, die das Potential und die Philosophie unserer Kund:innen widerspiegeln. Wir haben bereits zahlreiche internationale Projekte erfolgreich umgesetzt, von der Erstellung von Websites bis hin zu komplexen Software- und Datenbanksystemen. Unsere Leistungen werden von namhaften Kund:innen weltweit geschätzt."
        buttons={[{ link: '/kompetenzen', text: 'Unsere Stärken' }]}
      />
      <QuickFacts facts={facts} color="gray" />
      <ErpModule
        title="Sie benötigen eine Individual-Software?"
        text={
          <>
            Unsere maßgeschneiderte Individualsoftware optimiert Ihre
            Geschäftsprozesse und steigert die Effizienz. Wir entwickeln
            Lösungen, die perfekt auf Ihre Anforderungen zugeschnitten sind.
            Kundenspezifische Software steigert die Produktivität und verschafft
            Wettbewerbsvorteile, indem sie Prozesse automatisiert und optimiert.
            <br />
            Wir bieten eine breite Palette von Softwarelösungen, darunter
            Dokumenten-Management-Systeme, Produktinformationsmanagement-Systeme
            (PIM) und Web-Applikationen.
            <br />
            Kontaktieren Sie uns noch heute, um herauszufinden, wie wir Ihr
            Unternehmen mit maßgeschneiderten Softwarelösungen und ERP-Systemen
            unterstützen können. Kundenzufriedenheit und Qualität stehen für uns
            an erster Stelle.
          </>
        }
        image={bookingimage}
        link="/individual-software"
      />
      <BlogSlider cards={cards} singleRow={false} title="Aktuelle Trends für Ihre Website!"/>
      <SoftwareStack />
      <Brands brands={brands} title="Traum-Kunden? Gefunden!" />
      <TextBox title="Weitere Projekte" text="" />
      <ProjectSlider projects={projects} />
      <CallUs contacts={contacts} title="Rufen Sie uns an!" />
      <TextBox
        title="Walk with us on the holzweg"
        text={
          <span>
            <p>
              Bereit für eine erfolgreiche Zusammenarbeit mit einer führenden
              Webagentur in Innsbruck? Kontaktieren Sie uns jetzt für ein
              unverbindliches Beratungsgespräch oder nutzen Sie unsere Call-Back
              Funktion. Lassen Sie uns Ihnen zeigen, wie wir Ihr Unternehmen mit
              unserer jahrelangen Erfahrung in der Gestaltung und Entwicklung
              von individuellen Websites und Custom ERP-Systemen zum Erfolg
              führen können. Wenn der “holzweg” nach Ihrem Traumjob klingt,
              werfen Sie gerne einen Blick auf unsere Stellenangebote oder
              besuchen Sie uns auf unseren Social-Media-Kanälen.
            </p>
            <p>
              <a
                href="https://www.facebook.com/holzwegwebagentur"
                className="social-icon"
                rel="nofollow"
                target="_blank"
                style={{ color: '#ec6318' }}
              >
                <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
              </a>
              &nbsp;
              <a
                href="https://www.linkedin.com/company/holzweg-e-commerce-solutions"
                rel="nofollow"
                className="social-icon"
                target="_blank"
                style={{ color: '#f08607' }}
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              &nbsp;
              <a
                href="https://www.instagram.com/holzweg_gmbh"
                className="social-icon"
                rel="nofollow"
                target="_blank"
                style={{ color: '#f4a301' }}
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              &nbsp;
              <a
                href="https://www.youtube.com/user/holzwegagentur"
                className="social-icon"
                rel="nofollow"
                target="_blank"
                style={{ color: '#f4a301' }}
              >
                <FontAwesomeIcon icon={faYoutube} size="2x" />
              </a>
            </p>
          </span>
        }
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default IndexPage
