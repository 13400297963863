import React, { Component } from 'react'
import './index.scss'

class TextIconItem extends Component {
  constructor (props) {
    super(props)
  }

  render() {
    return (
      <div className="icon">
        <img src={this.props.icon} />
        <b><p>{this.props.title}</p></b>
        {this.props.text && <p>{this.props.text}</p>}
    </div>
    )
  }
}

export default TextIconItem