import React from 'react'
import TextIconItem from './TextIconItem'
import './index.scss'

const TextIconContainer = ({ textIcon = [], subtext}) => {
  return (
    <div className="text-icon">
      <div className="text-icon-container">
        {textIcon.map((textIcon, i) => (
          <TextIconItem {...textIcon} key={i} />
        ))}
      </div>
      <div className="text-icon-subtext">
        {subtext && <p>{subtext}</p>}
      </div>
    </div>
  )
}

export default TextIconContainer
